import { createApp } from 'vue'
import 'vant/lib/index.css';
import vant from 'vant';
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import SvgIcon from '@/components/components/svg/svg'
import rowDisplay from '@/components/components/rowDisplay'
import '@/components/components/svg'
import { showConfirmDialog } from 'vant';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
require("./utils/utils/polyfill"); // 去除touch事件谷歌提示
const app = createApp(App).use(store).use(router)
app.config.globalProperties.$showDialog = showConfirmDialog ;//全局挂载
app.component('svg-icon', SvgIcon)
app.component('rowDisplay', rowDisplay)
app.mount('#app');
app.use(vant);
// 全局前置守卫
import { getUserInfo } from '@/utils/my'
router.beforeEach((to, from, next) => {
//     console.log(to.path);
//     // 不需要用户信息的页面
    // let arr=['/home','/account','/vertifycode','/register','/findpassword']
    // if(arr.includes(to.path)){
    //     return next()
    // }
//     if (store.state.userInfo.userId) {
//         next()
//     }else{
//         getUserInfo().then(res=>{
//             if(res.code==200){
//                 let obj=res.data;
//                 if(res.data.userType==6){
//                     obj.vip=102;
//                 }
//                 store.commit("updateUserInfo", obj);
//                 next()
//             }
//         })
//     }
    if(!store.state.userInfo.userId){
        if(localStorage.user){
            store.state.userInfo=JSON.parse(localStorage.getItem("user"));
        }
    }
    if(to.query.token){
        localStorage.token=to.query.token
        getUserInfo().then(res=>{
            store.commit("updateUserInfo", res.data);
        })
    }
    next()
})