<template>
  <div class="box">
    <div 
      @touchstart="startTimer" 
      @touchend="clearTimer"
      @click="Fn" 
      :class="{
        'van-ellipsis': Number(props.num) === 1,
        'van-multi-ellipsis--l2': Number(props.num) === 2,
        'van-multi-ellipsis--l3': Number(props.num) === 3,
        'content':true}" 
    >{{ data.text + text }}</div>
    <van-popup 
    :show="data.pop" 
    :style="{ padding: '16px',borderRadius: '10px'}"
    @click-overlay="data.pop = false"
    >{{data.text}}</van-popup>
  </div>
</template>

<script setup>
import { defineProps, reactive, defineEmits } from 'vue'

const props = defineProps(['text','num','pop'])

const data = reactive({
  text: props.text,
  pop: false
})

const emit = defineEmits(['longFn', 'shortFn'])

const Fn = () => {
  emit('shortFn', data.text)
}

// 长按事件
let timer

const startTimer = () => {
  timer = setTimeout(() => {
    if (props.pop) {
      console.log('弹框');
      data.pop = true
    }
    emit('longFn', data.text)
  }, 600);
}

const clearTimer = () => {
  clearTimeout(timer);
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  .content {
    text-align: left;
  }
}
</style>
