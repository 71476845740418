import request from '@/utils/request';
// 获取用户信息
export const getUserInfo = (params) => {
  return request({
    url: '/system/user/myself',
    method: 'get',
    params
  })
}
// 修改用户非关键信息
export const updateUserinfo = (data) => {
  return request({
    url: '/system/user/update',
    method: 'post',
    data
  })
}
// 获取所有机构名称
export const getOrgList = (params) => {
  return request({
    url: '/system/org/list',
    method: 'get',
    params
  })
}
// 校验验证码
export const checkVerifyCode = (data) => {
  return request({
    url: '/system/verifycode/check',
    method: 'post',
    data
  })
}
// 修改用户关键信息 --需要验证码
export const updateUserKey = (data) => {
  return request({
    url: '/system/user/updateneedcode',
    method: 'post',
    data
  })
}
// 修改密码
export const updatepwd = (data) => {
  return request({
    url: '/system/user/changepwd',
    method: 'post',
    data
  })
}
// 发送反馈信息
export const postfeedback = (data) => {
  return request({
    url: '/system/feedBack',
    method: 'post',
    data
  })
}
// 注销用户
export const deleteUser = (data) => {
  return request({
    url: '/system/user/delete',
    method: 'post',
    data
  })
}