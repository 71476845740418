<template>
  <svg :class="state.svgClass" :style="{ width: size + 'px', height: size + 'px', color: color }" aria-hidden="true">
    <use :xlink:href="`#icon-${iconClass}`" />
  </svg>
</template>

<script>
import {ref,computed,reactive} from 'vue'
export default {
  props: {
    iconClass: { type: String, required: true },
    className: { type: String, default: '' },
    size: { type: Number, default: 12 },
    color: { type: String, default: "#000" }
  },
  setup(props){
    const iconClass=ref(props.iconClass)
    const state=reactive({
      svgClass:''
    })
    state.svgClass=computed(()=>{
      if (props.className) {
        return 'svg-icon ' + props.className
      } else {
        return 'svg-icon'
      }
    })
    return {state}
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>